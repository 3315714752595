:root {
  accent-color: var(--accent);
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light dark;
  --grey0: #000;
  --grey10: #1a1a1a;
  --grey20: #333;
  --grey30: #4d4d4d;
  --grey40: #666;
  --grey50: gray;
  --grey60: #999;
  --grey70: #b3b3b3;
  --grey80: #ccc;
  --grey90: #e6e6e6;
  --grey100: #fff;
  --root-color: white;
  --rad_sm: 5px;
  --rad_lg: 10px;
  --inspector-size: 32em;
  --tabs-height: 24pt;
  --bg-gradient: linear-gradient(to bottom, #fff3, transparent 100%);
  --text: #020303;
  --text-dim: #999;
  --bg-root: #fff;
  --bg0: #f1f4f4;
  --bg1: #bbb;
  --bg2: #ddd;
  --tabs-bg: #7e7e7d;
  --tabs-text: #fff;
  --tabs-border: #cbcbcbcb;
  --accent: #da6200;
  --text-on-accent: #fff;
  --bg0-shadow-color: color-mix(in hsl, var(--bg0) 90%, black);
  --stroke-L: 40%;
  --stroke-S: 100%;
  --stroke-blue: hsl(180, var(--stroke-S), var(--stroke-L));
  --stroke-orange: hsl(30, var(--stroke-S), var(--stroke-L));
  --stroke-green: hsl(120, var(--stroke-S), var(--stroke-L));
  --stroke-indigo: hsl(240, var(--stroke-S), var(--stroke-L));
  --stroke-red: hsl(0, var(--stroke-S), var(--stroke-L));
  --stroke-violet: hsl(300, var(--stroke-S), var(--stroke-L));
  --stroke-yellow: hsl(60, var(--stroke-S), var(--stroke-L));
  --bg-L: 70%;
  --bg-S: 100%;
  --bg-dark-L: 50%;
  --bg-dark-S: 60%;
  --bg-blue: hsl(180, var(--bg-S), var(--bg-L));
  --bg-green: hsl(120, var(--bg-S), var(--bg-L));
  --bg-indigo: hsl(240, var(--bg-S), var(--bg-L));
  --bg-orange: hsl(30, var(--bg-S), var(--bg-L));
  --bg-red: hsl(0, var(--bg-S), var(--bg-L));
  --bg-violet: hsl(300, var(--bg-S), var(--bg-L));
  --bg-yellow: hsl(60, var(--bg-S), var(--bg-L));
  --bg-darkblue: hsl(180, var(--bg-dark-S), var(--bg-dark-L));
  --bg-darkgreen: hsl(120, var(--bg-dark-S), var(--bg-dark-L));
  --bg-darkindigo: hsl(240, var(--bg-dark-S), var(--bg-dark-L));
  --bg-darkorange: hsl(30, var(--bg-dark-S), var(--bg-dark-L));
  --bg-darkred: hsl(0, var(--bg-dark-S), var(--bg-dark-L));
  --bg-darkviolet: hsl(300, var(--bg-dark-S), var(--bg-dark-L));
  --bg-darkyellow: hsl(60, var(--bg-dark-S), var(--bg-dark-L));
  --transition-duration: .5s;
}

@media (prefers-color-scheme: dark) {
  :root {
    --lightningcss-light: ;
    --lightningcss-dark: initial;
    --grey100: #000;
    --grey90: #1a1a1a;
    --grey80: #333;
    --grey70: #4d4d4d;
    --grey60: #666;
    --grey50: gray;
    --grey40: #999;
    --grey30: #b3b3b3;
    --grey20: #ccc;
    --grey10: #e6e6e6;
    --grey0: #fff;
    --root-color: black;
    --text: #f3f3f3;
    --text-dim: #766;
    --bg-root: #111;
    --bg0: #262222;
    --bg1: #696666;
    --bg2: #333;
    --stroke-L: 60%;
    --bg-L: 30%;
    --bg-dark-L: 40%;
  }
}

html {
  background: var(--bg-root);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-size: 10pt;
}

body {
  margin: 0;
}

#app {
  width: 100vw;
  height: 100svh;
  display: -ms-flexbox;
  display: flex;
}

@media (aspect-ratio <= 2 / 3), (width <= 700px) {
  html, body, #app {
    height: auto;
    min-height: 100svh;
  }

  #app {
    --inspector-size: auto;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

p {
  line-height: 1.5em;
}

h2 {
  border-radius: var(--rad_sm);
  font-size: 14pt;
}

hr {
  border: none;
  border-top: solid 1px var(--bg1);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}

kbd {
  color: color-mix(in srgb, currentcolor 30%, transparent);
  white-space: nowrap;
  border: 1px solid;
  border-radius: .3em;
  padding: .2em .3em;
  line-height: 1;
}

footer {
  color: var(--text-dim);
  text-align: center;
}

.link {
  color: var(--accent);
  text-decoration: underline;
}
/*# sourceMappingURL=index.9ed4ac56.css.map */
